<template>
    <b-card>
        <!-- Undo & Save Buttons -->
        <portal
            v-if="activated"
            to="profile-edit-actions"
        >
            <btn-undo
                class="btn-min-width mr-3"
                :resource="user"
            >
                {{ $t('common.actions.undoChanges') }}
            </btn-undo>

            <btn-resource
                variant="primary"
                class="btn-min-width"
                :resource="user"
                @click="user.save()"
            >
                {{ $t('common.actions.saveChanges') }}
            </btn-resource>
        </portal>

        <user-form :user="user" />
    </b-card>
</template>

<script>
import {User} from '@/models/User';
import KeptAlive from '@/components/common/mixins/KeptAlive';
import ConfirmLeaveUnsaved from '@/components/common/mixins/ConfirmLeaveUnsaved';
import UserForm from '@/components/users/Form';

export default {
    name: 'UserEdit',
    components: {UserForm},
    mixins: [ConfirmLeaveUnsaved, KeptAlive],
    props: {
        user: {
            type: User,
            required: true,
        },
    },
    computed: {
        hasUnsavedChanges() {
            return !!this.user.changed();
        },
    },
};
</script>
